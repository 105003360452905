<template>
  <div class="home">
    <div class="loginBox">
      <div>
        <div class="left">
          <div class="logo">
            <router-link to="/"
              ><img src="@/assets/images/logo_white.png" alt="Logo"
            /></router-link>
          </div>
          <h2>Welcome!</h2>
          <!-- <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis
            quae illum esse neque nam ipsam ullam quidem debitis itaque
            aspernatur.
          </p> -->
        </div>
        <div class="right">
          <div class="sign flex">
            <p>Don't have an account?</p>
            <router-link to="/join">Sign up</router-link>
          </div>
          <div class="login">
            <h2>Log in</h2>
            <div class="form">
              <input type="text" v-model="email" placeholder="Email" />
              <div class="password">
                <input
                  type="password"
                  v-model="password"
                  @keyup.enter="submit"
                  placeholder="Password"
                />
              </div>
              <div class="flex find">
                <!-- <router-link to="/findEmail">Forget Email?</router-link> -->
                <router-link to="/findPassword">Forget Password?</router-link>
              </div>
              <!-- <div v-if="error" class="error">
                가입하지 않은 이메일 혹은 잘못된 비밀번호입니다.
              </div> -->
              <button class="point large loginBtn" @click="submit">
                Log in
              </button>
            </div>
          </div>
          <div class="google">
            <h2>Log in with</h2>
            <!-- <button class="point large" @click="googleRenderClick" >
              <img src="@/assets/images/google.png" alt=""  /> Google
            </button> -->
            <div id="signin_button" ref="googleRenderButton"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://accounts.google.com/gsi/client" async defer></script>
<script>
import { format } from "@/mixins/format";
import { login, createGooglelogin } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      email: "",
      password: "",
      clientWidth: "",
      // error: false,
    };
  },
  mounted() {
    this.googlelogin();
  },
  methods: {
    loginEmailCheck(userType) {
      switch (userType) {
        case "":
          alert(
            "This email address is already in use, please login to your account."
          );
          break;
        case "GOOGLE":
          alert(
            "This email address is already in use with Google, please login using your google account."
          );
          break;
        default:
          alert("Please check the email again.");
      }
    },
    googlelogin() {
      this.clientWidth = document.querySelector(".loginBtn").offsetWidth;
      if (typeof window !== "undefined" && window.document) {
        window.addEventListener("load", () => {
          window.google.accounts.id.initialize({
            client_id:
              "719575761831-qevq7j07d99dmejsplap6kee3bmg9ke2.apps.googleusercontent.com",
            callback: this.handleCredentialResponse,
          });
          window.google.accounts.id.renderButton(
            document.getElementById("signin_button"),
            // customization attributes
            {
              theme: "outline",
              // width: divRef.current.clientWidth,
              width: this.clientWidth,
              size: "large",
            }
          );
        });
      }
      if (
        document.getElementsByTagName("iframe") &&
        document.getElementsByTagName("iframe").style
      ) {
        document.getElementsByTagName("iframe").style.margin = "";
      }
    },
    handleCredentialResponse(response) {
      // decodeJwtResponse() is a custom function defined by you
      // to decode the credential response.
      // const responsePayload = decodeJwtResponse(response.credential);
      //  console.log("ID: " + responsePayload.sub);
      //  console.log('Full Name: ' + responsePayload.name);
      //  console.log('Given Name: ' + responsePayload.given_name);
      //  console.log('Family Name: ' + responsePayload.family_name);
      //  console.log("Image URL: " + responsePayload.picture);
      //  console.log("Email: " + responsePayload.email);
      if (response.credential == "" || response.credential == undefined) {
        return alert("Login failed, please try again.");
      }
      let data = {
        credential: response.credential,
      };
      createGooglelogin(data).then((res) => {
        if (res.data.status != 200) {
          window.google.accounts.id.disableAutoSelect();
          if (res.data.status == 409) {
            return this.loginEmailCheck(res.data.data);
          }
        } else {
          this.$store.dispatch("LOGIN", res.data.accesstoken);
          document.location.href = "/";
        }
      });
    },
    submit() {
      if (this.email == "") {
        return alert("Enter your email address.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("Please enter your valid and entire email address.");
      } else if (this.password == "") {
        return alert("Enter password.");
      }
      let data = {
        email: this.email,
        password: this.password,
      };
      login(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("LOGIN", res.data.accesstoken);
          document.location.href = "/";
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
